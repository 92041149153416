import { Button, FormGroup, Intent, Tag } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { textTypesGoogle } from "./edit/message-form";

const StyledFormGroup = styled.div`
    position: relative;
    .hidden {
        display: none;
    }
    .button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;
const StyledLoadMore = styled.div`
    width: fit-content;
`;

const StyledContent = styled.div`
    display: ${({ hidden }) => (hidden ? "none" : "flex")};
    flex-direction: ${({ direction }) => direction || "row"};
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    .hidden {
        display: none !important;
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const StyledContentItem = styled.div`
    width: ${({ width }) => width || "100%"};
    display: flex;
    padding-right: 10px;
    flex-direction: column;
    .hidden {
        display: none !important;
    }
`;
const StyledAddButton = styled.div`
    width: fit-content;
    margin-bottom: 10px;
`;

StyledContentItem.propTypes = { children: PropTypes.node };
const MessageMultipleField = ({
    values,
    onChangeValue,
    name,
    changes = {},
    renderComponent,
    addElement,
    removeElement,
    label,
    errors = {},
    loading,
    disabled,
    onRevert,
    useColumnLayout = false,
}) => {
    const subErrors = Object.keys(errors).reduce((acc, k) => {
        const subKey = `${name}.`;
        if (k.startsWith(subKey)) {
            acc[k.substring(subKey.length)] = errors[k];
        }
        return acc;
    }, {});
    const [visibleCount, setVisibleCount] = useState(2);
    const handleLoadMore = () => {
        let lengthArray = values[name]?.length;

        setVisibleCount((prevCount) => Math.min(prevCount + 2, lengthArray || 0));
    };
    return (
        <StyledFormGroup>
            <FormGroup
                label={label}
                labelInfo={
                    changes[name] && (
                        <Tag minimal intent={Intent.PRIMARY} onRemove={onRevert ? () => onRevert(name) : null}>
                            Has changes
                        </Tag>
                    )
                }
                helperText={errors[name]}
                intent={errors[name] ? Intent.DANGER : changes[name] ? Intent.PRIMARY : Intent.NONE}
            >
                <StyledAddButton>
                    {addElement({
                        setValue: onChangeValue
                            ? (x) => {
                                  let newArray = [...values[name]];
                                  newArray[newArray.length] = x;
                                  onChangeValue(name, newArray);
                                  setVisibleCount(newArray.length, visibleCount + 1);
                              }
                            : null,
                    })}
                </StyledAddButton>
                <StyledContent direction={useColumnLayout ? "column" : "row"}>
                    {values[name]
                        ?.filter((elt) => {
                            return !(name === "texts" && textTypesGoogle.includes(elt.type));
                        })
                        ?.slice(0, visibleCount)
                        .map((entry, index) => {
                            const originalIndex = values[name].findIndex((elt) => elt === entry);
                            return (
                                <StyledContentItem width={useColumnLayout ? "100%" : "auto"}>
                                    {renderComponent({
                                        index: originalIndex,
                                        value: entry,
                                        setValue: onChangeValue
                                            ? (x) => {
                                                  let newArray = [...values[name]];
                                                  newArray[originalIndex] = x;
                                                  onChangeValue(name, newArray);
                                              }
                                            : null,
                                        removeEntry: null,
                                        disabled: loading || disabled,
                                        errors: subErrors,
                                    })}
                                    {removeElement({
                                        index: originalIndex,
                                        value: entry,
                                        setValue: onChangeValue
                                            ? (x) => {
                                                  let newArray = [...values[name]];
                                                  newArray = values[name].filter((_, i) => i !== originalIndex);
                                                  onChangeValue(name, newArray);
                                              }
                                            : null,
                                    })}
                                </StyledContentItem>
                            );
                        })}
                    {visibleCount <
                        (values[name]?.filter((elt) => {
                            return !(name === "texts" && textTypesGoogle.includes(elt.type));
                        }).length || 0) && (
                        <StyledLoadMore type="button">
                            <Button intent={Intent.PRIMARY} onClick={handleLoadMore} text="Load More" icon="more" />
                        </StyledLoadMore>
                    )}
                </StyledContent>
            </FormGroup>
        </StyledFormGroup>
    );
};

MessageMultipleField.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            slice: PropTypes.func,
            length: PropTypes.func,
            map: PropTypes.func,
            filter: PropTypes.func,
            findIndex: PropTypes.func,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    renderComponent: PropTypes.func.isRequired,
    addExternalImage: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
    onChangeValue: PropTypes.func,
    onRevert: PropTypes.func,
    changes: PropTypes.object,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    collapsed: PropTypes.bool,
    useColumnLayout: PropTypes.bool,
};

MessageMultipleField.defaultProps = {
    onChangeValue: null,
    onRevert: null,
    changes: {},
    errors: {},
    loading: false,
    disabled: false,
    collapsed: false,
    useColumnLayout: false,
};

export default MessageMultipleField;
