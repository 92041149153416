import { Intent, Tag, TextArea } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as DownArrow } from "../../../img/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../../img/up-arrow.svg";
import SimpleSelect from "../../common/simple-select";
import { StyledArrow } from "../message-field";
import { textTypesGoogle } from "./message-form";

const StyledFieldText = styled.div`
    border-radius: 3px;
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.lightGrayColor : "white")};
    border: 1px solid ${({ theme }) => theme.grayColor};
    padding: 5px;
    & > .actions {
        padding-top: 5px;
    }
`;

export const StyledPropertiesAsset = styled.div`
    display: flex;
    gap: 15px;
    padding: 5px 0;
`;

const text_types = [
    {
        value: "primary_text",
        label: "Primary Text",
    },
    {
        value: "short_title",
        label: "Short Title Text",
    },
    {
        value: "description_text",
        label: "Description Text",
    },
];
const text_roles = [
    {
        value: "default",
        label: "Default",
    },
];

const FieldText = ({ index, value, disabled, onChange }) => {
    const [visible, setVisible] = useState(true);
    return (
        !textTypesGoogle.includes(value?.type) && (
            <StyledFieldText>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <b>Text {value?.type}</b>
                    <StyledArrow hidden={visible} onClick={() => setVisible(!visible)}>
                        <DownArrow>SHOW</DownArrow>
                    </StyledArrow>
                    <StyledArrow hidden={!visible} onClick={() => setVisible(!visible)}>
                        <UpArrow>HIDE</UpArrow>
                    </StyledArrow>
                </div>
                {visible && (
                    <div className="actions">
                        <TextArea
                            fill
                            rows={6}
                            maxLength={512}
                            disabled={disabled}
                            onChange={(e) => {
                                onChange({ ...value, value: e.currentTarget.value });
                            }}
                            value={value?.value || ""}
                        />
                        <StyledPropertiesAsset>
                            <div>
                                <Tag minimal active intent={Intent.SUCCESS}>
                                    Type :{" "}
                                </Tag>
                                <SimpleSelect
                                    disabled={disabled}
                                    options={text_types}
                                    onSelect={(v) => onChange({ ...value, type: v })}
                                    selected={value?.type}
                                />
                            </div>
                            <div>
                                <Tag minimal active intent={Intent.PRIMARY}>
                                    Role :{" "}
                                </Tag>
                                <SimpleSelect
                                    disabled={disabled}
                                    options={text_roles}
                                    onSelect={(v) => onChange({ ...value, role: v })}
                                    selected={value?.role}
                                />
                            </div>
                        </StyledPropertiesAsset>
                    </div>
                )}
            </StyledFieldText>
        )
    );
};

FieldText.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.string,
        type: PropTypes.string,
        role: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
};

FieldText.defaultProps = {
    value: null,
    disabled: false,
};

export default FieldText;
